var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Select lading bill","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.lading_bills_receivable","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonEnter',{on:{"click":function($event){return _vm.select(item)}}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-break"},[(item.customer)?_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.customer.name,"noTranslate":""}}):_c('TMessageNotFound')],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"to":((_vm.lodash.getReferenceLink('lading_bill', item.container_id)) + "&tab=1"),"content":item.name || item.id.toString()}})],1)]}},{key:"contract_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.contract_id)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink('contract', item.contract_id),"content":item.contract_id}}):_c('TMessageNotFound')],1)]}},{key:"container_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.container_id)?_c('TLink',{attrs:{"to":((_vm.lodash.getReferenceLink('invoice', item.container_id)) + "&tab=1"),"content":item.container_id.toString()}}):_c('TMessageNotFound')],1)]}},{key:"freight_charge",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_paid,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.freight_charge,"currency":item.currency_id}})],1)])]}},{key:"freight_charge_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_outstanding,"currency":item.currency_id}})],1)]}},{key:"freight_charge_debited",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_debited,"currency":item.currency_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"customer-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"shipment_method_id-filter",fn:function(){return [_c('SSelectShipmentMethod',{attrs:{"value":_vm.filter.shipment_method_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "shipment_method_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"container_id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.container_id,"placeholder":"Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "container_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"contract_id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.contract_id,"placeholder":"Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "contract_id", $event)},_vm.filterChange]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }